import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-boldest m-0" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "card-body border-top p-9" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-12" }
const _hoisted_13 = { class: "form-group d-flex flex-wrap" }
const _hoisted_14 = { class: "timeline-label p-0" }
const _hoisted_15 = { class: "timeline-label fw-bolder text-gray-800 fs-6" }
const _hoisted_16 = { class: "timeline-badge" }
const _hoisted_17 = {
  key: 0,
  class: "fa fa-genderless text-warning fs-1"
}
const _hoisted_18 = {
  key: 1,
  class: "fa fa-genderless text-success fs-1"
}
const _hoisted_19 = {
  key: 2,
  class: "fa fa-genderless text-primary fs-1"
}
const _hoisted_20 = { class: "fw-mormal timeline-content text-muted ps-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_AddCallOutRecordsModal = _resolveComponent("AddCallOutRecordsModal")!
  const _component_AddQuickInquiryModal = _resolveComponent("AddQuickInquiryModal")!
  const _directive_auth = _resolveDirective("auth")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("common.nextsteps")), 1)
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary align-self-center me-2",
            "data-bs-toggle": "modal",
            "data-bs-target": "#modal_add_call_out_records",
            disabled: _ctx.formData?.id == '' || _ctx.loading
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("common.add") + _ctx.t("interactiveActivities.callOutRecords")), 1)
          ], 8, _hoisted_6),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary align-self-center me-2",
            "data-bs-toggle": "modal",
            "data-bs-target": "#modal_add_quick_inquiry",
            disabled: _ctx.formData?.id == '' || _ctx.loading
          }, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.t("common.add") + _ctx.t("interactiveActivities.quickInquiry")), 1)
          ], 8, _hoisted_8)
        ])), [
          [_directive_auth, { auth: ['update'] }]
        ])
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.list, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "timeline-item",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatDate(item.date)), 1),
                    _createElementVNode("div", _hoisted_16, [
                      (item.type == 'Call Out')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                        : _createCommentVNode("", true),
                      (item.type == 'Quick Inquiries')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                        : _createCommentVNode("", true),
                      (item.type == 'Campaign Hit')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", null, _toDisplayString(item.type), 1),
                      _createTextVNode(" " + _toDisplayString(item.comment), 1)
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _createVNode(_component_AddCallOutRecordsModal, {
      onUpdateList: _ctx.getSalesInteractionsInfo,
      accountId: _ctx.formData.account_id,
      taskSource: "3"
    }, null, 8, ["onUpdateList", "accountId"]),
    _createVNode(_component_AddQuickInquiryModal, {
      onUpdateList: _ctx.getSalesInteractionsInfo,
      accountId: _ctx.formData.account_id,
      taskSource: "4"
    }, null, 8, ["onUpdateList", "accountId"])
  ], 64))
}