
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSalesInteractions } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import AddCallOutRecordsModal from "@/views/task-management/call-out-records/AddCallOutRecordsModal.vue";
import AddQuickInquiryModal from "@/views/task-management/quick-inquiry/AddQuickInquiryModal.vue";
import moment from "moment";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "influencer-groups-information",
  components: {
    AddCallOutRecordsModal,
    AddQuickInquiryModal,
  },
  setup() {
    const loading = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      account_id: "",
      list: [],
    });

    const getSalesInteractionsInfo = () => {
      loading.value = true;
      ApiSalesInteractions.getTimelineOfTask({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          formData.value = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onBeforeMount(() => {
      getSalesInteractionsInfo();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDate,
      loading,
      formData,
      getSalesInteractionsInfo,
    };
  },
});
